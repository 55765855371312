<template>
  <Loader :value="promises.fetch" class="residential-page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'
import Shop from '@kvass/shop'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/residential.js'

// pagebuilder components
import ResidentialComponents from '@/components/Residential'

export default {
  mixins: [BrowserApiMixin('residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val).then(data => {
          if (!data) return this.$router.push({ name: 'notfound' })
        })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Residential', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...ResidentialComponents }
    },
  },
  methods: {
    ...mapActions('Residential', ['fetch']),
    gotoLead() {
      document.querySelector('#lead').scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    Shop,

    Blocks,
  },
}
</script>

<style lang="scss">
.residential-page {
  --section-padding-inline: 0rem;
  background-color: white;

  @include respond-above('desktop') {
    font-size: 18px;
  }

  &__header {
    flex-direction: column !important;

    justify-content: center !important;
    gap: 0;

    @include respond-above('phone') {
      flex-direction: row !important;
    }
  }
  &__text {
    width: 100%;
    padding: 1.5rem 1.5rem 0;

    @include respond-above('phone') {
      width: 50%;
    }

    h2 {
      font-size: 1.61rem;
      font-weight: 700;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;

      @media (min-width: 544px) {
        font-size: 1.84rem;
      }

      @include respond-above('phone') {
        font-size: 2.3rem;
      }
    }
  }
  &__call-to-action {
    width: 100%;
    text-align: center;
    padding: 2rem 1.5rem;

    @include respond-above('phone') {
      width: 30%;
      align-self: flex-end;
      margin-bottom: 1.5rem;
    }
  }
  &__features {
    padding: 0rem 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0;
    max-width: 60rem;
    margin: 0 auto;

    // override .kpb-flex
    @media (max-width: 600px) {
      flex-direction: row;
    }

    @include respond-above('phone') {
      padding: 0rem 1rem;
    }

    @include respond-above('tablet') {
      padding: 0rem 1.5rem;
    }
  }
  &__feature {
    padding: 20px;
    margin: 0 0 15px;
    width: 50%;

    @media (min-width: 544px) {
      width: 33%;
    }

    @include respond-above('phone') {
      width: 20%;
      margin: 1rem 0rem;
      font-size: 1.1rem;
      padding: 0 0.6rem;
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    @include respond-above('tablet') {
      font-size: 1.15rem;
    }
  }
  &__icon {
    width: 40px;
    height: 40px;
    .kpb-image__image {
      object-fit: contain;
      background-color: unset;
    }

    @include respond-above('phone') {
      width: 50px;
      height: 50px;
    }
    @include respond-above('tablet') {
      width: 60px;
      height: 60px;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 1rem;
  }
  &__subtitle {
    font-size: 1rem;
  }
  &__gallery {
    .gallery__main-image,
    .gallery__thumbnail {
      background-color: unset;
    }
  }
  &__masonry {
    // color name Birch
    background-color: rgb(64, 61, 41);
    padding-top: 5px;

    .masonry__content {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
    }
    .masonry__item {
      margin-left: 5px;
      margin-bottom: 5px;

      flex-basis: 365.714px;
      flex-grow: 1.82857;
      justify-content: center;
      transition: transform 0.3s, -webkit-transform 0.3s;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
  &__lead {
    // @/components/Residential/Lead
  }
}
</style>
